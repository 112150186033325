import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  token: localStorage.getItem('userToken'),
  userData: {}
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserToken: (state, action) => {
      state.token = action.payload
    },
    setStoreUserData: (state, action) => {
      state.userData = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserToken, setStoreUserData } = userSlice.actions

export default userSlice.reducer

export const getStoreUserToken = (state) => state.user.token
export const getStoreUserLogStatus = (state) => state.user.token !== null
export const getStoreUserData = (state) => state.user.userData

