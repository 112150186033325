import React, {memo, useEffect, useState} from 'react'
import './header.scss'
import {GradientButton} from "../../ui/button";
import {useHistory} from 'react-router-dom'
import {Authenticate} from "../authenticate";
import {useDispatch, useSelector} from "react-redux";
import {UserMenu} from "./userMenu";
import {AppRoutes} from "../../../app/routers";
import {getStoreDarkTheme} from "../../../features/interfaceSlice";
import {
  getStoreIbzBalance,
  getStoreMaticBalance,
  getStoreProviderType,
  getStoreWalletSelected
} from '../../../features/walletSlice';
import _ from 'lodash'
import {IconMetamask, IconWalletConnect} from "../../../assets";
import {LOCAL_STORAGE_KEY, tokens} from "../../../app/constants";
import {Row} from "../../ui/utils";
import {PROVIDER_TYPE} from '../../../web3/provider';
import {getWalletTokenBalance} from "../../../web3";

export const Header = memo(({ showBack = true }) => {
  const darkTheme = useSelector(getStoreDarkTheme)
  const wallet = useSelector(getStoreWalletSelected)
  const providerType = useSelector(getStoreProviderType)
  const ibz = useSelector(getStoreIbzBalance)
  const matic = useSelector(getStoreMaticBalance)
  const history = useHistory()
  const dispatch = useDispatch()

  const [openLogin, setOpenLogin] = useState(false)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [ibzBalance, setIbzBalance] = useState(ibz || Number(localStorage.getItem(LOCAL_STORAGE_KEY.IBZ_BALANCE)))
  const [maticBalance, setMaticBalance] = useState(matic || Number(localStorage.getItem(LOCAL_STORAGE_KEY.MATIC_BALANCE)))

  // const isLogged = !!wallet

  useEffect(() => {
    refreshBalances()
  }, [wallet])


  const refreshBalances = () => {
    if (wallet) {
      getWalletTokenBalance(wallet, tokens.IBZ).then(res => setIbzBalance(res))
      getWalletTokenBalance(wallet, tokens.MATIC).then(res => setMaticBalance(res))
    } else {
      setIbzBalance(0)
      setMaticBalance(0)
      localStorage.setItem(`${tokens.IBZ}_BALANCE`, '0')
      localStorage.setItem(`${tokens.MATIC}_BALANCE`, '0')
    }
  }

  let shortAddress
  if(!_.isEmpty(wallet)) {
    shortAddress = wallet
      ? `${wallet.substring(0, 4)}...${wallet.substring(38, 42)}`
      : ''
  }
  const goToHome = () => {
    history.push(AppRoutes.home())
  }

  const walletIcon = providerType === PROVIDER_TYPE.METAMASK ? IconMetamask : IconWalletConnect

  return (
    <div id="header">
      <Row className={'header-logo-small'}>
        <img onClick={goToHome} src={'/logo512.png'} alt={'logo'} className="image"/>
      </Row>
      <Row justifyCenter alignCenter flex={1}>
        <img src={'/ibz-logo.png'} className="logo" alt={'logo'}/>
        <h1 className="logo-name">Ibiza Token</h1>
      </Row>
      <Row justifyEnd flex={1}>
        <GradientButton gradient="border" className={'walletButton balance'}>
          <span className='walletButtonText'>{ibzBalance?.toFixed(5)} IBZ</span>
        </GradientButton>
        <GradientButton gradient="border" className={'walletButton balance'}>
          <span className='walletButtonText'>{maticBalance?.toFixed(5)} MATIC</span>
        </GradientButton>
        <GradientButton gradient="solid" className={'connectButton balance'} active onClick={() => setOpenLogin(true)} >
          {shortAddress ?
            <div className={'content'}>
              <span className='walletButtonText'>{shortAddress}</span>
              <img src={walletIcon} className='walletIcon'/>
            </div> :
            <span>Connect</span>
          }
        </GradientButton>
      </Row>

      {showUserMenu && <UserMenu open={showUserMenu} onClose={() => setShowUserMenu(false)}/>}
      {openLogin && <Authenticate open={openLogin} onClose={() => setOpenLogin(false)}/>}
    </div>
  )
})

