import React, { memo } from 'react'
import "./row.scss"

export const Row = memo(({ children, className, spaceBetween, spaceAround, alignCenter, justifyCenter, justifyEnd, flex, style, ...props }) => {

  let alignItems = undefined
  let justifyContent = undefined
  if (alignCenter) {
    alignItems = 'center'
  }
  if (spaceBetween) {
    justifyContent = 'space-between'
  }
  if (spaceAround) {
    justifyContent = 'space-around'
  }
  if (justifyCenter) {
    justifyContent = 'center'
  }
  if (justifyEnd) {
    justifyContent = 'flex-end'
  }

  const rowStyle = {
    justifyContent,
    alignItems,
    flex: flex ? 1 : undefined,
    display: 'flex',
    flexDirection: 'row',
    ...style
  }

  return (
    <div {...props} style={rowStyle} className={`row ${className}`}>
      {children}
    </div>
  )
})
