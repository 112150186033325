import Web3 from 'web3';
import {CONTRACTS, MAIN_ERC20_ABI} from '../index';
import detectEthereumProvider from '@metamask/detect-provider';
import {
  getStoreMaticBalance,
  getStoreProviderType,
  getStoreWalletSelected,
  setStoreWalletIbzBalance
} from '../../features/walletSlice';
import {store} from '../../app/store';
import {PROVIDER_TYPE, web3} from '../provider';
import {useMoralis} from "react-moralis";
import _ from 'lodash'
import {LOCAL_STORAGE_KEY} from "../../app/constants";
export const ABI_QUICKSWAP = require('./abiQuickswap.json');
export const ABI_IBIZA_LIQUIDITY_MINING = require('./abiIbizaLiquidityMining.json');
export const privateKey = '0xe6ac265051e55ca4c22dc3dd7142db8f1b9371515f4cc82e1451a73d99e72a89'; // 20 matic
// export const web3 = new Web3(AlchemyUrl);

export const getCorrectWeb3BasedOnProviderType = async (moralis) => {
  const state = store.getState()
  const currentProvider = getStoreProviderType(state) || localStorage.getItem(LOCAL_STORAGE_KEY.PROVIDER_TYPE)
  console.debug({currentProvider})
  let web3
  if(currentProvider === PROVIDER_TYPE.METAMASK) {
    web3 = await moralis.enableWeb3()
  }
  if(_.toLower(currentProvider) === PROVIDER_TYPE.WALLET_CONNECT){
    web3 = await moralis.enableWeb3({ provider: PROVIDER_TYPE.WALLET_CONNECT });
  }

  return web3
}


export const addLiquidityToIbzContract = async (quantity, moralis) => {
  try {
    const state = store.getState()
    const fromAddress = getStoreWalletSelected(state)
    const web3 = await getCorrectWeb3BasedOnProviderType(moralis)

    const contract = new web3.eth.Contract(ABI_IBIZA_LIQUIDITY_MINING, CONTRACTS.IBZ_LIQUIDITY_MINING, {from: fromAddress});
    const gasPrice = await web3.eth.getGasPrice();
    const transaction = await contract.methods.deposit(
      web3.utils.toHex(0),
      web3.utils.toWei(quantity.toString(), 'ether')
    ).send({
      from: fromAddress,
      gasPrice: Number(gasPrice),
      gasLimit: web3.utils.toHex(600000),
      to: CONTRACTS.IBZ_LIQUIDITY_MINING,
      value: '0x0',
      // nonce: count,
    })
    console.log({transaction})
    return transaction;
  } catch (e) {
    console.debug({e})
    return e
  }
}
export const removeLiquidityToIbzContract = async (quantity, moralis) => {
  console.debug({ABI_IBIZA_LIQUIDITY_MINING})
  const state = store.getState()
  const fromAddress = getStoreWalletSelected(state)
  const web3 = await getCorrectWeb3BasedOnProviderType(moralis)
  const contract = new web3.eth.Contract(ABI_IBIZA_LIQUIDITY_MINING, CONTRACTS.IBZ_LIQUIDITY_MINING, {from: fromAddress});
  const gasPrice = await web3.eth.getGasPrice();

  const transaction = await contract.methods.withdraw(
    web3.utils.toHex(0),
    web3.utils.toWei(quantity.toString(), 'ether')
  ).send({
    from: fromAddress,
    gasPrice: Number(gasPrice),
    gasLimit: web3.utils.toHex(600000),
    to: CONTRACTS.IBZ_LIQUIDITY_MINING,
    value: '0x0',
  })
  console.log({removeTransaction: transaction})
  return transaction;
}


export const approveLP = async (moralis) => {
  const state = store.getState()
  const fromAddress = getStoreWalletSelected(state)
  const web3 = await getCorrectWeb3BasedOnProviderType(moralis)
  const contract = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS.LP_MATIC_IBZ);
  const gasPrice = await web3.eth.getGasPrice();
  const transaction = await contract.methods.approve(
    CONTRACTS.IBZ_LIQUIDITY_MINING,
    '115792089237316195423570985008687907853269984665640564039457584007913129639935'
  ).send({
    from: fromAddress,
    gasPrice: Number(gasPrice),
    gasLimit: web3.utils.toHex(600000),
    to: CONTRACTS.LP_MATIC_IBZ,
    value: '0x0',
    // nonce: count,
  })
  console.log({approveTransactionLP: transaction})
}

export const approveIBZF = async (moralis) => {
  const state = store.getState()
  const fromAddress = getStoreWalletSelected(state)
  const web3 = await getCorrectWeb3BasedOnProviderType(moralis)
  const contract = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS.IBZ_LIQUIDITY_MINING);
  const gasPrice = await web3.eth.getGasPrice();
  const transaction = await contract.methods.approve(
    CONTRACTS.IBZ_LIQUIDITY_MINING,
    '115792089237316195423570985008687907853269984665640564039457584007913129639935'
  ).send({
    from: fromAddress,
    gasPrice: Number(gasPrice),
    gasLimit: web3.utils.toHex(600000),
    to: CONTRACTS.LP_MATIC_IBZ,
    value: '0x0',
    // nonce: count,
  })
  console.log({approveTransactionIBZF: transaction})
}


export const checkApproveLP = async (wallet) => {
  const contract = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS.LP_MATIC_IBZ);
  const transaction = await contract.methods.allowance(
    wallet,
    CONTRACTS.IBZ_LIQUIDITY_MINING,
  ).call()
  // console.log({checkApproveTransactionLP: transaction})
  return Number(transaction)
}
export const checkApproveIBZF = async (wallet) => {
  const contract = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS.IBZ_LIQUIDITY_MINING);
  const transaction = await contract.methods.allowance(
    wallet,
    CONTRACTS.IBZ_LIQUIDITY_MINING,
  ).call()
  // console.log({checkApproveTransactionIBZF: transaction})
  return Number(transaction)
}

export const getWalletLPBalance = async (address) => {
  try {
    const contract = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS.LP_MATIC_IBZ);
    const balance = await contract.methods.balanceOf(address).call();
    console.debug({balance})
    return Number(balance) / 1e18
  } catch (e) {
    console.debug({errorIbzBalance: e})
    return 0
  }
}
export const getWalletIBZFBalance = async (address) => {
  try {
    const contract = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS.IBZ_LIQUIDITY_MINING);
    const balance = await contract.methods.balanceOf(address).call();
    console.debug({balance})
    return Number(balance) / 1e18
  } catch (e) {
    console.debug({errorIbzBalance: e})
    return 0
  }
}
export const getPendingLPRewards = async (wallet) => {
  try {
    const contract = new web3.eth.Contract(ABI_IBIZA_LIQUIDITY_MINING, CONTRACTS.IBZ_LIQUIDITY_MINING, {from: wallet});
    const pendingRes = await contract.methods.pending(
      web3.utils.toHex(0),
      wallet
    ).call()
    console.debug({pendingRes})
    return Number(pendingRes) / 1e18;
  } catch (e) {
    console.debug({e})
  }
}

const blockTime = 2.2 // Seconds

export const getLiquidityMiningApr = async () => {
  // Total IBZF
  const contract1 = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS.IBZ_LIQUIDITY_MINING);
  const totalSupplyWei = await contract1.methods.totalSupply().call()
  const totalSupplyIBZF = Number(totalSupplyWei) / 1e18

  const contract2 = new web3.eth.Contract(ABI_IBIZA_LIQUIDITY_MINING, CONTRACTS.IBZ_LIQUIDITY_MINING);
  const ibzPerBlockWei = await contract2.methods.rewardPerBlock().call()
  const ibzPerBlock = Number(ibzPerBlockWei) / 1e18
  const ibzPerDay = 60 * 60 * 24 / blockTime * ibzPerBlock
  const ibzPerYear = ibzPerDay * 365

  // Total LP
  const contract3 = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS.LP_MATIC_IBZ);
  const totalSupply3Wei = await contract3.methods.totalSupply().call()
  const totalSupply3 = Number(totalSupply3Wei) / 1e18

  const contract4 = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS.IBZ);
  const balanceLPWei = await contract4.methods.balanceOf(CONTRACTS.LP_MATIC_IBZ).call();
  const balanceLP = Number(balanceLPWei) / 1e18

  // valore in ibz per LP
  const ibzForLp = balanceLP / totalSupply3 * 2

  const totalIbzWorthInIBZF = totalSupplyIBZF * ibzForLp

  const annualApr = ibzPerYear / totalIbzWorthInIBZF * 100
  return annualApr
}
//
// export const removeLiquidityToIbzContract = async () => {
//   console.log({ABI_IBIZA_LIQUIDITY_MINING})
//   const account = getAccountFromPrivateKey(privateKey)
//   const fromAddress = account.address
//
//   const contract = new web3.eth.Contract(ABI_IBIZA_LIQUIDITY_MINING, CONTRACT_IBIZA_LIQUIDITY_MINING, {from: fromAddress});
//
//   const data = await contract.methods.withdraw(
//     0,
//     web3.utils.toHex(1e18)
//   );
//   await signDataAndSendTransaction(data, fromAddress)
// }
//
// export const getRewardPerBlock = async () => {
//   console.log({ABI_IBIZA_LIQUIDITY_MINING})
//   const account = getAccountFromPrivateKey(privateKey)
//   const fromAddress = account.address
//
//   const contract = new web3.eth.Contract(ABI_IBIZA_LIQUIDITY_MINING, CONTRACT_IBIZA_LIQUIDITY_MINING, {});
//
//   const data = await contract.methods.rewardPerBlock().call();
//   console.log({data})
// }
//
//
// const signDataAndSendTransaction = async (data, fromAddress) => {
//   const count = await web3.eth.getTransactionCount(fromAddress);
//   const gasPrice = await web3.eth.getGasPrice();
//
//   const rawTransaction = {
//     from: fromAddress,
//     gasPrice: Number(gasPrice),
//     gasLimit: web3.utils.toHex(600000),
//     to: CONTRACT_IBIZA_LIQUIDITY_MINING,
//     value: '0x0',
//     data: data.encodeABI(),
//     nonce: count,
//   };
//
//   const transaction = await web3.eth.accounts.signTransaction(rawTransaction, privateKey);
//   console.log({transaction});
//
//   const sent = await web3.eth.sendSignedTransaction(transaction.rawTransaction);
//   console.log({sent});
//   return transaction;
// }
//
//
// export const getAccountFromPrivateKey = (privateKey) => {
//   const importAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
//   console.log({importAccount});
//   return importAccount
// };
