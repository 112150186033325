import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from "lodash";

const initialState = {
  walletSelected: '',
  providerType: ''
}

export const userSlice = createSlice({
  name: 'interface',
  initialState,
  reducers: {
    setStoreWalletSelected: (state, action) => {
      state.walletSelected = action.payload
    },
    setStoreWalletProviderType: (state, action) => {
      state.providerType = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setStoreWalletSelected, setStoreWalletProviderType } = userSlice.actions

export default userSlice.reducer

export const getStoreWalletSelected = (state) => _.get(state ,'wallet.walletSelected')
export const getStoreMaticBalance = (state) => ''
export const getStoreIbzBalance = (state) => ''
export const getStoreProvider = (state) => ''
export const getStoreNftList = (state) => ''
export const getStoreProviderType = (state) => _.get(state ,'wallet.providerType')

