import React from "react";
import './card.scss'

export const Card = (
  { children,
    gradient = 'none',
    borderSize = 'thin',
    active = false,
    className = '',
    style = {},
    ...props
  }
) => {

  const gradientClass = gradient !== 'none' ? `card-gradient-${gradient}` : ''
  const cardBorder = `card-border-${borderSize}`
  const cardStatus = active ? 'active' : ''

  return (
    <div
      className={`card ${gradientClass} ${cardBorder} ${cardStatus} ${className}`}
      style={{...style}}
      {...props}
    >
        {children}
    </div>
  );
}
