import React, {memo, useState} from 'react'
import './connect.scss'
import {GradientButton} from "../../ui/button";
import {Col, Row} from "../../ui/utils";
import {useTranslation} from 'react-i18next';
import {IconMetamask, IconWalletConnect} from "../../../assets";
import {onConnect, PROVIDER_TYPE} from "../../../web3/provider";
import {useMoralis} from "react-moralis";

export const Connect = memo(({ onConfirm }) => {
  const [ error, setError] = useState('')
  const { enableWeb3, Moralis } = useMoralis()
  const [loading, setLoading] = useState(false);
  const [loadingWc, setLoadingWc] = useState(false);
  const { t } = useTranslation()

  const onClickConnectMetamask = async () => {
    setLoading(true)
    const isMetaMaskInstalled = await Moralis.isMetaMaskInstalled();
    if(isMetaMaskInstalled) {
      await onConnect(PROVIDER_TYPE.METAMASK, enableWeb3)
      onConfirm()
    }else {
      console.debug({isMetaMaskInstalled})
      setError('Metamask not installed')
    }
    setLoading(false)
  }

  const onClickWalletConnect = async () => {
    await onConnect(PROVIDER_TYPE.WALLET_CONNECT, enableWeb3)
    onConfirm()
  }

  return (
    <Col id="connect" alignCenter style={{ width: '80%' }}>
      <GradientButton gradient="border" onClick={onClickConnectMetamask} solid className="connectButton" loading={loading}>
        <Row spaceBetween alignCenter className={'content'}>
          <span className={'btn-text'}>{t('metamask')} </span>
          <img src={IconMetamask} className='walletIcon' alt={'metamask'}/>
        </Row>
      </GradientButton>
      <GradientButton gradient="border" onClick={onClickWalletConnect} solid className="connectButton">
        <Row spaceBetween alignCenter className={'content'}>
          <span className={'btn-text'}>{t('walletconnect')} </span>
          <img src={IconWalletConnect} className='walletIcon wc' alt={'walletconnect'}/>
        </Row>
      </GradientButton>
      {!!error && <p className="auth-error">{error}</p>}
    </Col>
  )
})
