import React, { memo } from 'react'
import "./backgroundLines.scss"

export const BackgroundLines = memo(({ children }) => {

  return (
    <div id="backgroundLines" >
      {children}
      {/*<img className="image" src={'images/backgroundLines.png'}/>*/}
    </div>
  )
})
