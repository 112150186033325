import { configureStore } from '@reduxjs/toolkit'
// import { combineReducers } from '@reduxjs/toolkit'
import interfaceSlice from "../features/interfaceSlice";
import walletSlice from '../features/walletSlice';

// const resettableRootReducer = (state, action) => {
//   if (action.type === reset.actions.resetApp.type) {
//     return rootReducer(undefined, action)
//   }
//   return rootReducer(state, action)
// }

export const store = configureStore({
  reducer: {
    interface: interfaceSlice,
    wallet: walletSlice,
  },
})
