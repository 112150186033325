import React, { memo } from 'react'
import {Button as MaterialButton} from "@material-ui/core";
import "./button.scss"

export const Button = memo(({ children, className, onClick, style }) => {
  return (
    <MaterialButton
      id="button"
      onClick={onClick}
      className={className}
      style={style}
      variant="text"
    >
      {children}
    </MaterialButton>
  )
})
