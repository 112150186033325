import React, {memo, useState} from 'react'
import './userMenu.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Modal} from "@material-ui/core";
import {Col} from "../../ui/utils";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {AppRoutes} from "../../../app/routers";
import {logout} from "../../../app/constants";
import { Theme } from "../../../app/App";
import {Connect} from "../authenticate/connect";
import {GradientButton} from "../../ui/button";
import {SignIn, SignUp} from "../authenticate";
import {IconWalletConnect} from "../../../assets";
import {onConnectWalletConnect} from "../../../web3/provider";

export const UserMenu = memo(({ open, onClose }) => {
  const history = useHistory()
  const [showConnect, setShowConnect] = useState(false)
  const dispatch = useDispatch()

  const onLogout = async () => {
    // await logout()
    // onClose()
    // history.push(AppRoutes.home())
  }
  const goToUserDetail = () => {
  }

  const onConnect = async () => {
    await onConnectWalletConnect(onClose);
  };

  return (
    <Modal id="UserMenu" open={open} onClose={onClose}>
      <Theme>
        <Col className="container" alignCenter style={{ backgroundImage: "url(/images/backgroundLinesModal.png)" }}>
          <FontAwesomeIcon size={'2x'} icon={['fas', "user"]}/>
          <h5 onClick={goToUserDetail}>Profile</h5>
          <h5 onClick={onLogout}>Disconnect</h5>
          {/*<h5 onClick={onConnect}>*/}
          {/*  <div className={'iconButtonRow'}>*/}
          {/*    <span className={'text'}> WalletConnect </span>*/}
          {/*    <img src={IconWalletConnect} className={'walletIcon'}/>*/}
          {/*  </div>*/}
          {/*</h5>*/}
        </Col>
      </Theme>
    </Modal>
  )
})
