import axios from 'axios';
import { store } from "../app/store";
import { getStoreUserToken } from "../features/userSlice";
import _ from 'lodash';

console.debug(process.env.REACT_APP_API_URL)

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers['Content-Type'] = 'application/json';

axios.interceptors.request.use(conf => {
    const state = store.getState();
    const token = getStoreUserToken(state);
    conf.headers['Authorization'] = `Bearer ${token}`;
    return conf;
  },
  error => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use((response) => {
  return response;
}, async function (error) {
  console.log({error})
  console.log({url: error.request.responseURL, data: error.response.data })
  if (401 === _.get(error, 'response.status', null)) {
  } else {
    return Promise.reject(error);
  }
});
