import { Component } from 'react'

import './ErrorBoundary.scss'

class ErrorBoundary extends Component {
  state = {
    hasError: false
  }

  componentDidCatch(error, errorInfo) {
    console.error({ error, errorInfo });
  }

  static getDerivedStateFromError(error) {
    console.error({ error });
    return { hasError: true }
  }

  render () {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <h2>Oops...</h2>
          <span className="error-code">404</span>
          <h2>Not Found</h2>
        </div>
      )
    }
    return this.props.children
  }
}

export default  ErrorBoundary
