//METAMASK

import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import _ from 'lodash';
import {store} from '../app/store';
import {getStoreWalletSelected, setStoreWalletProviderType, setStoreWalletSelected} from '../features/walletSlice';
import WalletConnectProvider from '@walletconnect/web3-provider';
import {LOCAL_STORAGE_KEY} from "../app/constants";
import {AlchemyUrl, PROVIDER_OPTION} from './index';

export const PROVIDER_TYPE = {
  METAMASK: 'metamask',
  WALLET_CONNECT: 'walletconnect'
}

let web3 = new Web3(AlchemyUrl);
let provider


//WALLETCONNECT
export const onConnectWalletConnect = async (onConfirm) =>{
  provider = new WalletConnectProvider({
    rpc: { 137: AlchemyUrl },
    chainId: 137
  });


  const state = store.getState()
  const wallet = getStoreWalletSelected(state)

  try {
    console.log({provider})

    await provider.enable();

    web3 = new Web3(provider);
    console.log({web3})
    //store.dispatch(setStoreWalletProvider(web3))

    const accounts = await web3.currentProvider.accounts;
    const chainId = await web3.currentProvider.chainId;

    store.dispatch(setStoreWalletSelected(accounts[0]))
    store.dispatch(setStoreWalletProviderType(PROVIDER_TYPE.WALLET_CONNECT))

    onConfirm()

    provider.on("disconnect", (code, reason) => {
      store.dispatch(setStoreWalletSelected({}))
      // store.dispatch(setStoreWalletIbzBalance(0))
      // store.dispatch(setStoreWalletMaticBalance(0))
      onConfirm()
    });

    provider.on("connect", () => {
      onConfirm()
    });

    provider.on("accountsChanged", (accounts) => {
      store.dispatch(setStoreWalletSelected({...wallet, accounts}))
      onConfirm()
    });

    provider.on("chainChanged", (chainId) => {
      store.dispatch(setStoreWalletSelected({...wallet, chainId}))
      onConfirm()
    });

  } catch (e) {
    console.log({e})
    await provider.enable();

    await provider.close();

    // If the cached provider is not cleared,
    // WalletConnect will default to the existing session
    // and does not allow to re-scan the QR code with a new wallet.
    // Depending on your use case you may want or want not his behavior.
    // await web3Modal.clearCachedProvider();
    // walletConnectProvider = null;
    onConfirm()
  }
}

export const onDisconnectWalletConnect = async () =>{
  // let walletConnectProvider = new WalletConnectProvider({
  //   rpc: { 137: 'https://polygon-mainnet.g.alchemy.com/v2/tdkD0jY3u2HPcar8yexAkdixWdsFD8VN' },
  //   chainId: 137
  // });
  await provider.enable();

  try {
    await provider.disconnect();
    await provider.close();

    // If the cached provider is not cleared,
    // WalletConnect will default to the existing session
    // and does not allow to re-scan the QR code with a new wallet.
    // Depending on your use case you may want or want not his behavior.

    provider = null;
  } catch (e) {
    console.log({e})
  }

}

export const onConnect = async (provider, enableWeb3) => {
  let options = { chainId: PROVIDER_OPTION.CHAIN_ID}
  if(provider === PROVIDER_TYPE.WALLET_CONNECT) {
    options = {...options, provider: PROVIDER_TYPE.WALLET_CONNECT}
  }

  await enableWeb3({...options ,
    async onSuccess(web3) {
      let accounts, chainId, wallet
      if(provider === PROVIDER_TYPE.WALLET_CONNECT ) {
        accounts = await web3.currentProvider.accounts
        wallet = _.first(accounts)
        store.dispatch(setStoreWalletProviderType(PROVIDER_TYPE.WALLET_CONNECT))
        localStorage.setItem(LOCAL_STORAGE_KEY.PROVIDER_TYPE, PROVIDER_TYPE.WALLET_CONNECT)
      } else {
        accounts = await web3.currentProvider.selectedAddress
        wallet = accounts
        store.dispatch(setStoreWalletProviderType(PROVIDER_TYPE.METAMASK))
        localStorage.setItem(LOCAL_STORAGE_KEY.PROVIDER_TYPE, PROVIDER_TYPE.METAMASK)
        switchToPolygon()
      }
      chainId = await web3.currentProvider.chainId

      // const accounts = provider === 'wc'  ? await web3.currentProvider.accounts : await web3.currentProvider.selectedAddress;;
      // const chainId = await web3.currentProvider.chainId;

      // console.log({wallet, chainId})
      store.dispatch(setStoreWalletSelected(wallet))
      localStorage.setItem(LOCAL_STORAGE_KEY.WALLET_SELECTED, _.toString(wallet))
    },
    onError(error) {
      console.error({error})
    },
    onComplete() {
      console.debug('Complete')
    }
  })
  console.debug({web3})
}


export const onConnectMetamask = async () => {
  try {
    provider = await detectEthereumProvider()
    await provider.enable();
    console.debug({provider})

    if (!provider) {
      return
    }
    web3 = new Web3(provider);
    console.debug({web3})
    //console.log(JSON.stringify(web3))

    const accounts = await web3.eth.accounts.currentProvider.isMetamask;
    const chainId = await web3.currentProvider.chainId;

    console.debug({CHAIN: web3.utils.fromAscii(chainId)})

    const wallets = await provider.request({method: 'eth_requestAccounts'});
    const wallet  = _.first(wallets)
    console.log({metamaskWallet: wallet})

    store.dispatch(setStoreWalletProviderType(PROVIDER_TYPE.METAMASK))
    store.dispatch(setStoreWalletSelected(wallet))

    return wallet

  }catch (e) {
    console.log({e})
  }
}

export const onDisconnect = async (Moralis) => {
  console.log({provider})
  console.log({web3})

  try {
    localStorage.clear()
    store.dispatch(setStoreWalletSelected(''))
    store.dispatch(setStoreWalletProviderType(''))
    // await provider.enable()
    // await provider.disconnect();
    // await provider.close();
  } catch (e) {
    console.log({e})
  }
}



export {
  web3,
  provider
}

export const switchToPolygon = async () => {
  const provider = await detectEthereumProvider()
  try {
    const switchRequest = await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x89' }],
    });
    console.debug({switchRequest})
    return true
  } catch (switchError) {
    console.log({switchError})
    // This error code indicates that the chain has not been added to MetaMask.
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0x89',
        chainName: 'Polygon Mainnet',
        rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
        blockExplorerUrls: ['https://polygonscan.com'],
        nativeCurrency: {
          "name": "MATIC",
          "symbol": "MATIC",
          "decimals": 18
        },
      }],
    });
    return true
  }
}
