import React, {memo, useEffect} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTumblrSquare,
  faInstagramSquare,
  faLinkedin,
  faTwitterSquare,
  faFacebookSquare, faFacebookF
} from "@fortawesome/free-brands-svg-icons";
import { getUserData } from "../../../api";
import {useSelector} from "react-redux";
import {getStoreUserData} from "../../../features/userSlice";

import './footer.scss'
import {getStoreDarkTheme} from '../../../features/interfaceSlice';
import {useTranslation} from "react-i18next";
import {Row} from "../../ui/utils";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";
import {SocialLink} from "../../ui/button/socialLink";
import {faGithub} from "@fortawesome/free-brands-svg-icons/faGithub";
import {faTelegram} from "@fortawesome/free-brands-svg-icons/faTelegram";
import {faTwitter} from "@fortawesome/free-brands-svg-icons/faTwitter";
import {faMedium} from "@fortawesome/free-brands-svg-icons/faMedium";

const icons = {
  instagram: faInstagram,
  twitter: faTwitter,
  tumblr: faTumblrSquare,
  linkedin: faLinkedin,
  facebook: faFacebookF,
  github: faGithub,
  telegram: faTelegram,
  medium: faMedium
}

const about = 'Ibiza Token is an ERC-20 governance token on the Ethereum Mainnet, created expressly for and dedicated to the island of Ibiza. Ibiza Token leverages the full potential of blockchain technology by directly connecting the local economy with a broader audience in order to develop a new digital trade ecosystem based on non-intermediation, efficiency, and security.'
const legal = 'Copyright © 2021, Ibiza Token'
const privacy = 'Privacy Policy & Conditions of Use'
export const Footer = memo(({ }) => {

  const isDarkTheme = useSelector(getStoreDarkTheme)
  const { t } = useTranslation()
  // const openWhitepaper = () => {
  //   window.open('');
  // }

  return (
    <div id="footer">
      <Row alignCenter justifyCenter>
        <img src={'/ibz-logo.png'} className="logo"/>
        <h2 className="logo-name">Ibiza Token</h2>
      </Row>
      <p className="about">{about}</p>
      <ul className="social-links">
        <li>
          <a href={'https://github.com/ibizatoken/'} target="_blank" >
            <FontAwesomeIcon icon={icons.github} size="2x" color={'white'} className={'icon'}/>
          </a>
        </li>
        <li>
          <a href={'https://t.me/ibztoken/'} target="_blank" >
            <FontAwesomeIcon icon={icons.telegram} size="2x" color={'white'}/>
          </a>
        </li>
        <li>
          <a href={'https://www.instagram.com/ibizatoken/'} target="_blank" >
            <FontAwesomeIcon icon={icons.instagram} size="2x" color={'white'}/>
          </a>
        </li>
        <li>
          <a href={'https://twitter.com/ibizatoken/'} target="_blank" >
            <FontAwesomeIcon icon={icons.twitter} size="2x" color={'white'}/>
          </a>
        </li>
        <li>
          <a href={'https://medium.com/@ibizatoken/'} target="_blank" >
            <FontAwesomeIcon icon={icons.medium} size="2x" color={'white'}/>
          </a>
        </li>
      </ul>
      <p className="legal">{legal}{" — "}
        <a href="https://www.ibizatoken.com/ibz-privacy-policy.html" className={'privacy'}>
          {privacy}
        </a>
      </p>
    </div>
  )
})
