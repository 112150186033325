import React, {useEffect} from 'react'
import {useSelector} from "react-redux";
import {getStoreUserToken} from "../features/userSlice";
import {useHistory} from "react-router-dom";
import {AppRoutes} from "./routers";

export const Auth = () => {

  const history = useHistory()

  useEffect(() => {
  }, [])

  return (
    <div/>
  )
}
