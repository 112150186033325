import React, {memo, useState} from 'react'
import './authenticate.scss'
import {GradientButton} from "../../ui/button";
import {Col} from "../../ui/utils";
import {Modal} from "@material-ui/core";
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {Theme} from "../../../app/App";
import {
  getStoreWalletSelected,
  setStoreWalletProviderType,
  setStoreWalletSelected
} from '../../../features/walletSlice';
import {Connect} from "./connect";
import {useMoralis} from "react-moralis";

export const Authenticate = memo(({ open, onClose }) => {
  const [ showForm, setShowForm ] = useState('connect')
  const dispatch = useDispatch()
  const [ error, setError ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const { Moralis } = useMoralis()
  const { t } = useTranslation()

  const wallet = useSelector(getStoreWalletSelected);

  const onClickConnect = () => {
    setShowForm('connect')
  }
  const onClickDisconnect = () => {
    Moralis.Web3.onDisconnect(function(accounts) {
      console.debug(accounts);
    });
    localStorage.clear()
    dispatch(setStoreWalletSelected(''))
    dispatch(setStoreWalletProviderType(''))

    onCloseModal()
  }

  const onCloseModal = () => {
    setShowForm('')
    onClose()
  }

  return (
    <Modal id="authenticate" open={open} onClose={onCloseModal}>
      <Theme>
        <Col className="container" alignCenter style={{ backgroundImage: "url(/images/backgroundLinesModal.png)" }}>
          <img src={"/logo512.png"} alt={'logo'} className="logo"/>

          {error && <p className="auth-error">{error}</p>}

          {!!wallet && <div>
            <GradientButton gradient="border" onClick={onClickDisconnect} solid className="buttonSignIn">
              Disconnect
            </GradientButton>
          </div>}
          {!wallet && <Connect onConfirm={onCloseModal}/>}
        </Col>
      </Theme>
    </Modal>
  )
})
