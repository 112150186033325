import React, { memo, useState } from 'react'
import {Button, CircularProgress} from '@material-ui/core';
import {Card} from "../card";

import "./gradientButton.scss"
import {PRIMARY_COLOR} from '../../../app/constants';

export const GradientButton = memo(({ children, type = 'button', gradient, borderSize, active, applyHover, className, onClick, style, loading, disabled }) => {

  const [hoverGradientButton, setHoverGradientButton] = useState(null)

  return (
    <Card
      id="gradientButton"
      className={`${className} ${applyHover && hoverGradientButton ? 'card-hovered' : ''} ${disabled && 'btn-disabled'}`}
      gradient={(applyHover && hoverGradientButton) || gradient}
      borderSize={borderSize}
      style={style}
      active={active}
      onMouseEnter={() => setHoverGradientButton('solid')}
      onMouseLeave={() => setHoverGradientButton(null)}
    >
      <Button
        type={type}
        onClick={onClick}
        className={`button ${((applyHover && hoverGradientButton) || gradient === 'solid') && 'solid-button'}`}
        variant="text"
        disabled={disabled}
      >
        {loading ? <CircularProgress size={20} color={'#b9ebf7'}/> : children}
      </Button>
    </Card>
  )
})
