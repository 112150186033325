import React from 'react'
import { Routes } from './routers'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import './theme.scss'
import {Provider, useSelector} from 'react-redux'
import {store} from "./store";
import '../api/axios'
import { I18nextProvider } from 'react-i18next'
import i18n from '../i18n'
import { useMoralis } from "react-moralis";
import {getStoreDarkTheme} from "../features/interfaceSlice";
library.add(fas, far, fal, fad)

export const Theme = ({children}) => {
  const isDarkTheme = useSelector(getStoreDarkTheme)
  const classTheme = isDarkTheme ? 'dark-theme' : 'light-theme'
  return (
    <div className={classTheme}>
      <div id="app">
        {children}
      </div>
    </div>
  )
}

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Theme>
          <Routes />
        </Theme>
      </Provider>
    </I18nextProvider>
  )
}

export default App
