import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  darkTheme: true
}

export const userSlice = createSlice({
  name: 'interface',
  initialState,
  reducers: {
    setStoreDarkTheme: (state, action) => {
      state.darkTheme = action.payload
      localStorage.setItem('darkTheme', action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setStoreDarkTheme } = userSlice.actions

export default userSlice.reducer

export const getStoreDarkTheme = (state) => state.interface.darkTheme || false

