import React, {useEffect, useState} from 'react'
import {BackgroundLines} from '../components/ui/background'
import "../components/layout/home/Home.scss"
import {Button, GradientButton} from '../components/ui/button';
import {Card} from "../components/ui/card";
import {Col, Row} from "../components/ui/utils";
import {InputText} from "../components/ui/input";
import {
  addLiquidityToIbzContract,
  approveIBZF,
  approveLP,
  checkApproveIBZF,
  checkApproveLP, getLiquidityMiningApr,
  getPendingLPRewards,
  getWalletIBZFBalance,
  getWalletLPBalance,
  removeLiquidityToIbzContract
} from '../web3/liquidityMining/indexTestnet';
import {useSelector} from 'react-redux';
import {getStoreProviderType, getStoreWalletSelected} from '../features/walletSlice';
import {getWalletTokenBalance} from '../web3';
import {LOCAL_STORAGE_KEY, tokens, truncateNumber} from '../app/constants';
import {useMoralis} from "react-moralis";
import {PROVIDER_TYPE} from "../web3/provider";

export const HomePage = () => {

  const [isApprovedLP, setIsApprovedLP] = useState(false)
  const [isApprovedIBZF, setIsApprovedIBZF] = useState(false)
  const {Moralis, isInitialized, ...rest} = useMoralis();
  const [liquidityToAdd, setLiquidityToAdd] = useState(0)
  const [liquidityToRemove, setLiquidityToRemove] = useState(0)
  const [LPBalance, setLPBalance] = useState(0)
  const [IBZFBalance, setIBZFBalance] = useState(0)
  const [rewardBalance, setRewardBalance] = useState(0)
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [annualApr, setAnnualApr] = useState(0)

  const [ibzBalance, setIbzBalance] = useState(Number(localStorage.getItem(LOCAL_STORAGE_KEY.IBZ_BALANCE)))
  const [maticBalance, setMaticBalance] = useState(Number(localStorage.getItem(LOCAL_STORAGE_KEY.MATIC_BALANCE)))
  const [allBtnDisable, setAllBtnDisable] = useState(false)
  const wallet = useSelector(getStoreWalletSelected)
  const providerType = useSelector(getStoreProviderType)
  const isWalletConnect = providerType === PROVIDER_TYPE.WALLET_CONNECT

  useEffect(() => {

  }, [wallet])

  useEffect(() => {
    refreshBalances()
    const interval = setInterval(refreshBalances, 10 * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [wallet])

  const refreshBalances = () => {
    getLiquidityMiningApr().then(apr => {
      setAnnualApr(apr)
    })
    if (wallet) {
      getWalletTokenBalance(wallet, tokens.IBZ).then(res => setIbzBalance(res))
      getWalletTokenBalance(wallet, tokens.MATIC).then(res => setMaticBalance(res))
      getWalletLPBalance(wallet).then(lpRes => {
        console.debug({lpRes})
        setLPBalance(lpRes)
      })
      getWalletIBZFBalance(wallet).then(IBZFres => {
        console.debug({IBZFres})
        setIBZFBalance(IBZFres)
      })
      getPendingLPRewards(wallet).then(reward => {
        console.debug({reward})
        setRewardBalance(reward)
      })
      checkApproveLP(wallet).then(approvedLP => {
        setIsApprovedLP(!!approvedLP)
        console.debug({approvedLP})
      })
      checkApproveIBZF(wallet).then(approvedIBZF => {
        setIsApprovedIBZF(!!approvedIBZF)
        console.debug({approvedIBZF})
      })
      setAllBtnDisable(false)
    } else {
      setAllBtnDisable(true)
      setLPBalance(0)
      setIBZFBalance(0)
      setLiquidityToAdd(0)
      setLiquidityToRemove(0)
      setRewardBalance(0)
    }
  }

  const onClickAddLiquidity = async () => {
    setLoadingAdd(true)
    let timeout
    if (isWalletConnect) {
      timeout = setTimeout(() => {
        refreshBalances()
        setLoadingAdd(false)

      }, 20 * 1000)
    }
    await addLiquidityToIbzContract(Number(liquidityToAdd), Moralis)
    if (isWalletConnect) clearTimeout(timeout)
    refreshBalances()
    setLiquidityToAdd(0)
    setLoadingAdd(false)
  }

  const onClickRemoveLiquidity = async () => {
    setLoadingRemove(true)
    let timeout

    if (isWalletConnect) {
      timeout = setTimeout(() => {
        refreshBalances()
        setLoadingRemove(false)

      }, 20 * 1000)
    }
    await removeLiquidityToIbzContract(Number(liquidityToRemove), Moralis)
    refreshBalances()
    if (isWalletConnect) clearTimeout(timeout)

    setLiquidityToRemove(0)
    setLoadingRemove(false)
  }

  const onSetMaxLiquidity = () => {
    setLiquidityToAdd(truncateNumber(LPBalance, 3))
  }
  const onSetMaxLiquidityToRemove = () => {
    setLiquidityToRemove(truncateNumber(IBZFBalance, 3))
  }


  const onClickApproveLP = async () => {
    // checkApproveLiquidityToIbzContract()
    setLoadingAdd(true)
    try {
      let timeout

      if (isWalletConnect) {
        timeout = setTimeout(() => {
          refreshBalances()
          setLoadingAdd(false)

        }, 20 * 1000)
      }
      await approveLP(Moralis)
      if (isWalletConnect) clearTimeout(timeout)
      refreshBalances()
      setLoadingAdd(false)
    } catch (e) {
      setLoadingAdd(false)
    }
  }

  const onClickApproveIBZF = async () => {
    // checkApproveLiquidityToIbzContract()
    setLoadingRemove(true)
    try {
      let timeout
      if (isWalletConnect) {
        timeout = setTimeout(() => {
          refreshBalances()
          setLoadingRemove(false)
        }, 20 * 1000)}
      await approveIBZF(Moralis)
      if (isWalletConnect) clearTimeout(timeout)

      refreshBalances()
      setLoadingRemove(false)
    } catch (e) {
      setLoadingRemove(false)
    }
  }

  const openQuickSwapAddLiquidity = () => {
    window.open("https://quickswap.exchange/#/add/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/0x428ac1de3fc08c0f3a47745c964f7d677716981f", "_blank")
  }

  const subTitle = () => {
    return (
    <div>
      <p className={'text'}>Staking your LP tokens
      <a className="quickswap-link" onClick={openQuickSwapAddLiquidity}> (WMATIC - IBZ on quickswap) </a>
      and get reward from liquidity mining program.<br/>
      When you deposit LP token, you get IBZF token and your rewards will start growing.
      </p>
      <p className={'text'}>When you add or remove liquidity, your rewards are automatically transfer to your wallet.</p>
    </div>
    )
  }

  return (
    <BackgroundLines>
      <div id={'home'}>
        <Col alignCenter>
          <Card className={'card card-gradient-border box-liquidity-info'}>
            <h1 className={'title'} style={{marginTop: -30}}>Farming</h1>
            {subTitle()}
            {/*<GradientButton onClick={getLiquidityMiningApr}/>*/}
            <div style={{marginTop: 30}} className={'input-row-container'}>
              <div>
                <InputText
                  inputClass={'input-liquidity'}
                  inputType={'number'}
                  defaultValue={liquidityToAdd}
                  value={liquidityToAdd}
                  min={0}
                  onChange={newVal => setLiquidityToAdd(Number(newVal))}
                />
                <Row spaceBetween className={'input-text-button'}>
                  Your LP: {truncateNumber(LPBalance, 3)}
                  <Button className={'max-btn'} onClick={onSetMaxLiquidity}>Max</Button>
                </Row>
              </div>
              <Col flex={1} className={'input-button'}>
                {isApprovedLP &&
                <GradientButton
                  gradient={'solid'}
                  active
                  disabled={!wallet || liquidityToAdd === 0 || liquidityToAdd > LPBalance}
                  onClick={onClickAddLiquidity}
                  className={`btn-liquidity`}
                  loading={loadingAdd}
                >
                  Add liquidity
                </GradientButton>}
                {!isApprovedLP &&
                <GradientButton
                  gradient={'solid'}
                  active
                  disabled={!wallet}
                  onClick={onClickApproveLP}
                  className={`btn-liquidity`}
                  loading={loadingAdd}
                >
                  Approve LP
                </GradientButton>}
              </Col>
            </div>
            <div style={{marginTop: 40}} className={'input-row-container'}>
              <div>
                <InputText
                  inputClass={'input-liquidity input-gradient-border'}
                  inputType={'number'}
                  value={liquidityToRemove}
                  min={0}
                  onChange={newVal => setLiquidityToRemove(Number(newVal))}
                />
                <Row spaceBetween className={'input-text-button'}>
                  Your IBZF: {truncateNumber(IBZFBalance, 3)}
                  <Button className={'max-btn'} onClick={onSetMaxLiquidityToRemove}>Max</Button>
                </Row>
              </div>
              <Col flex={1} className={'input-button'}>
                {isApprovedIBZF &&
                <GradientButton
                  gradient={'solid'}
                  active
                  disabled={(!wallet || liquidityToRemove === 0 || liquidityToRemove > IBZFBalance)}
                  onClick={onClickRemoveLiquidity}
                  className={`btn-liquidity`}
                  loading={loadingRemove}
                >
                  Remove liquidity
                </GradientButton>}
                {!isApprovedIBZF &&
                <GradientButton
                  gradient={'solid'}
                  active
                  onClick={onClickApproveIBZF}
                  disabled={!wallet}
                  className={`btn-liquidity`}
                  loading={loadingRemove}
                >
                  Approve IBZF
                </GradientButton>}
              </Col>
            </div>

            <h4 style={{color: 'white', marginTop: 50, marginBottom: 0}}>Actual reward APR: {annualApr?.toFixed(2)}%</h4>

            <h3 style={{color: 'white', marginTop: 30, marginBottom: 0}}>Your
              rewards: {rewardBalance?.toFixed(3)} IBZ</h3>
          </Card>
        </Col>
      </div>
    </BackgroundLines>
  )
}
