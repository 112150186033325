import Web3 from 'web3';
import _ from 'lodash';
import detectEthereumProvider from '@metamask/detect-provider';
import {store} from '../app/store';
import {web3} from './provider';
import {contracts, tokens} from "../app/constants";
export const MAIN_ERC20_ABI = require('./abiErc20.json');

// Test
// export const CONTRACTS = {
//   IBZ: '0xf794dc2b0f6cadf9a2fe62cbc42f999284c995a5', //Test
//   LP_MATIC_IBZ: '0x6306e39bdc1fe2fe2237b9a200e8c33a8746ee46',
//   IBZ_LIQUIDITY_MINING: '0x79A9950F3Ddb7038715DA8a951afb1143281DCdC'
// }
// export const PROVIDER_OPTION = {
//   CHAIN_ID: 80001
// }
// export const AlchemyUrl = 'https://matic-mumbai.chainstacklabs.com/';


// MAINNET
export const CONTRACTS = {
  IBZ: '0x428ac1de3fc08c0f3a47745c964f7d677716981f',
  LP_MATIC_IBZ: '0x41b3de329F181132d019afF3ce1d3B2105248eC4',
  IBZ_LIQUIDITY_MINING: '0xdD56432A6A3D578686982B5AA5446C108350378B'
}
export const PROVIDER_OPTION = {
  CHAIN_ID: 137
}
export const AlchemyUrl = 'https://polygon-mainnet.g.alchemy.com/v2/tdkD0jY3u2HPcar8yexAkdixWdsFD8VN';


export const switchToPolygon = async () => {
  const provider = await detectEthereumProvider()
  try {
    const switchRequest = await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x89' }],
    });
    console.debug({switchRequest})
    return true
  } catch (switchError) {
    console.debug({switchError})
    // This error code indicates that the chain has not been added to MetaMask.
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0x89',
        chainName: 'Polygon Mainnet',
        rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
        blockExplorerUrls: ['https://polygonscan.com'],
        nativeCurrency: {
          "name": "MATIC",
          "symbol": "MATIC",
          "decimals": 18
        },
      }],
    });
    return true
  }
}

export const getWalletMaticBalance = async (address) => {
  try {
    const balance = await web3.eth.getBalance(address);
  } catch (e) {
    console.error({errorMaticBalance: e})
  }
  // store.dispatch(setStoreWalletMaticBalance(Number(balance) / 1e18))
}

export const getWalletTokenBalance = async (address, tokenName) => {
  let balance
  if(tokenName === tokens.MATIC){
    balance = await web3.eth.getBalance(address);
  } else {
    const contract = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS[tokenName]);
    balance = await contract.methods.balanceOf(address).call();
  }
  localStorage.setItem(`${tokenName}_BALANCES`, _.toString(Number(balance) / 1e18))
  return Number(balance) / 1e18
}

export const getWalletIbzBalance = async (address) => {
  try {
    const contract = new web3.eth.Contract(MAIN_ERC20_ABI, CONTRACTS.IBZ);
    const balance = await contract.methods.balanceOf(address).call();
    // store.dispatch(setStoreWalletIbzBalance(Number(balance) / 1e18))
  } catch (e) {
    console.log({errorIbzBalance: e})
  }
}

export const getWalletNftList = async (address) => {
  // console.log({getWallet: address})
  // const contract = new web3.eth.Contract(MAIN_ERC20_ABI, IBZ_CONTRACT);
  // const balance = await contract.methods.balanceOf(address).call();
  // store.dispatch(setStoreWalletMaticBalance(Number(balance) / 1e18))
}

export const signDataAndSendTransactionEncoded = async (data, toContract, fromAddressPk, fromAddress, extraData) => {
  const { slow, defaultCount, send = true} = extraData;
  const count = defaultCount || await web3.eth.getTransactionCount(fromAddress);

  const gasPrice = await web3.eth.getGasPrice();
  const block = await web3.eth.getBlock('latest');
  let gasLimit = block.gasLimit;
  const balance = await web3.eth.getBalance(fromAddress);
  console.debug({fee: gasLimit * Number(gasPrice)});
  if (gasLimit * Number(gasPrice) > Number(balance) || slow) {
    console.log('Low matic');
    gasLimit = 600000;
  }

  console.debug({gasLimit});
  console.debug({gasPrice});

  const rawTransaction = {
    from: fromAddress,
    gasPrice: Number(gasPrice),
    gasLimit: web3.utils.toHex(gasLimit),
    to: toContract,
    value: '0x0',
    data: data.encodeABI(),
    nonce: count,
  };

  const transaction = await web3.eth.accounts.signTransaction(rawTransaction, fromAddressPk);
  console.log({transaction});

  if (send) {
    const sent = await web3.eth.sendSignedTransaction(transaction.rawTransaction);
    console.log({sent});
    return sent;
  }

  return transaction;
};

