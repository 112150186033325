import _ from 'lodash'
import {create} from "ipfs-http-client";
import {setStoreUserData, setUserToken} from "../features/userSlice";
import {AppRoutes} from "./routers";
import {store} from "./store";
import { BrowserRouter } from "react-router-dom";
import {setStoreWalletSelected} from '../features/walletSlice';
import {numberToHex} from 'web3-utils';

export const PRIMARY_COLOR = '#E60065'

export const TYPES = {
  IMAGE: "image",
  MUSIC: "music",
  GIF: "gif",
  VIDEO: "video",
}
export const TYPES_LIST = _.map(TYPES, (value, key) => ({ value: value, label: value }))


export const getUrlByCid = (cid) => {
  let newCid = cid
  if (cid.includes('ipfs://')) {
    newCid = cid.substring(7, cid.length)
  }
  return `https://ipfs.infura.io/ipfs/${newCid}`
}

export const getCidByUrl = (url) => {
  let newCid = url
  if (url.includes('ipfs://')) {
    newCid = url.substring(7, url.length)
  }
  return newCid
}

export const getImageFromNftDetail = (nft) => {
  const propImage = _.get(nft, 'props.image', '')
  return !propImage || propImage === "ipfs://" ? '/images/ibizaCard.png' : getUrlByCid(propImage)
}

const client = create('https://ipfs.infura.io:5001/api/v0')

export const uploadImageAndGetCid = async (file) => {
  try {
    const added = await client.add(file)
    return added.path
  } catch (error) {
    console.log('Error uploading file: ', error)
    return ''
  }
}

export const truncateNumber = (number, precision) => {
  console.log(number)
  const exp = Math.pow(10, precision)
  return Math.floor((number * exp)) / exp
}

export const LOCAL_STORAGE_KEY = {
  IBZ_BALANCE: 'IBZ_BALANCE',
  WALLET_SELECTED: 'walletSelected',
  MATIC_BALANCE: 'MATIC_BALANCE',
  WALLETS: 'wallets',
  CHAIN_ID: 'chainId',
  PROVIDER_TYPE: 'providerType',
  WEB_3: 'web3'
}

export const tokens = {
  IBZ: 'IBZ',
  MATIC: 'MATIC'
}

export const contracts = {
  // IBZ: '0x428ac1de3fc08c0f3a47745c964f7d677716981f'
  IBZ: '0xf794dc2b0f6cadf9a2fe62cbc42f999284c995a5'
}
