import React, { memo } from 'react'
import "./col.scss"

export const Col = memo(({ children, className, spaceBetween, alignCenter, justifyCenter, flex, style, ...props }) => {

  let alignItems = undefined
  let justifyContent = undefined
  if (alignCenter) {
    alignItems = 'center'
  }
  if (spaceBetween) {
    justifyContent = 'space-between'
  }
  if (justifyCenter) {
    justifyContent = 'center'
  }


  const rowStyle = {
    justifyContent,
    alignItems,
    flex: flex ? 1 : undefined,
    display: 'flex',
    flexDirection: 'column',
    ...style
  }

  return (
    <div {...props} className={className} style={rowStyle}>
      {children}
    </div>
  )
})
