import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./inputText.scss"

export const InputText = (
  {
    type = 'input',
    name,
    required,
    defaultValue,
    label,
    min,
    inputType = 'text',
    inputClass,
    inputIcon,
    labelStyle = { width: 80 },
    icon = null,
    iconSize = 'sm',
    errorMessage,
    stacked,
    disabled = false,
    full = false,
    onChange = () => {},
    value,
    ...rest
  }
) => {

  return (
      <div id="input-text" className={`${stacked ? 'stacked' : ''} ${full ? 'full' : ''}`}>
          {inputIcon}
        {label
          ? <label className="input-label" style={labelStyle}>{label}</label>
          : <FontAwesomeIcon icon={icon} size={iconSize} className="input-icon" />
        }
        <input
          type={inputType}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          min={min}
          className={`${inputClass} ${disabled ? 'disabled' : ''}`}
          onChange={(e) => onChange(e.target.value)}
          // onChange={register.onChange ? register.onChange : (e) => onChange(e.target.value)}
        />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
  )
}
