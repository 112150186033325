import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {Header} from "../components/layout/header";
import {Footer} from "../components/layout/footer";
import {HomePage} from "../pages/HomePage";
import {Auth} from "./auth";
import ErrorBoundary from "../components/layout/error-boundary/ErrorBoundary";

export const AppRoutes = {
  home: () => '/'
}

export const Routes = () => {
  return (
    <BrowserRouter basename={'/'}>
      {/*<Web3Provider/>*/}
      <Auth/>
      <Header showBack={false}/>
      <ErrorBoundary>
        <Switch>
          <Route exact path={AppRoutes.home()} component={HomePage} />
        </Switch>
      </ErrorBoundary>
      <Footer />
    </BrowserRouter>
  )
}
